import { useWidth } from '@wap-client/core'
import React from 'react'

const HorizontalDriver = ({ mobileVisible = false }) => {
  const width = useWidth()

  if (!mobileVisible && width < 860) {
    return null
  } else
    return (
      <div className="horizontal-driver">
        <div className="line" />
        <div className="dots">
          <div className="dot dot-one"></div>
          <div className="dot dot-two"></div>
          <div className="dot dot-three"></div>
        </div>
        <div className="line" />
      </div>
    )
}

export default HorizontalDriver
