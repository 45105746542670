import Button from '@/components/base/button'
import { Column } from '@/components/base/gridview'
import { functions, useApp } from '@wap-client/core'
import React, { useState } from 'react'
import { StgFormResponseType } from './types'
import { queryService } from '@wap-client/services'
import form from '@/components/base/form'
import { PulseLoader } from 'react-spinners'

const StgForm = () => {
  const app = useApp()

  const [formStatus, setFormStatus] = useState<StgFormResponseType>('default')
  const [formValues, setFormValues] = useState<any>({})
  const [loadingState, setloadingState] = useState(false)
  const [formErrorValues, setFormErrorValues] = useState<any>({
    name: '',
    email: '',
    topic: '',
    message: '',
  })

  console.log(formStatus)

  const onSubmit = (e: any) => {
    e.preventDefault()
    const { name, email, topic, message } = e.target.elements

    setFormValues({
      name: name.value,
      email: email.value,
      topic: topic.value,
      message: message.value,
    })

    setloadingState(true)

    const fromData = {
      columns: [
        {
          name: 'baslik',
          data: {
            refs: [],
            value: formValues.name,
          },
        },
        {
          name: 'name',
          data: {
            refs: [],
            value: formValues.name,
          },
        },
        {
          name: 'email',
          data: {
            refs: [],
            value: formValues.email,
          },
        },
        {
          name: 'topic',
          data: {
            refs: [],
            value: formValues.topic,
          },
        },
        {
          name: 'message',
          data: {
            refs: [],
            value: formValues.message,
          },
        },
      ],
    }

    queryService
      .create(
        app.environment,
        {
          name: 'create-app-stg-form',
          language: app.language,
        },
        fromData
      )
      .then((response) => {
        if (response) {
          setFormStatus('success')
          setloadingState(false)
          setTimeout(() => {
            setFormStatus('default')
            setFormValues({
              name: '',
              email: '',
              topic: '',
              message: '',
            })
          }, 3000)
        }
      })

      .catch(() => {
        setFormStatus('error')
        setTimeout(() => setFormStatus('default'), 3000)
      })
  }
  return (
    <Column className="contact-form-layout">
      {/* <Toaster position='bottom-center' /> */}
      <span className="form-title">
        {app.settings.translations['contact-us']}
      </span>
      <form onSubmit={onSubmit} className="input-group contact-form">
        <div className="form-input">
          <input
            required
            name="name"
            placeholder={app.settings.translations['nameandsurname']}
            value={formValues.name}
            type="text"
            onChange={(e) => {
              setFormValues({ ...formValues, name: e.target.value })
            }}
          />
        </div>
        <div className="form-input">
          <input
            required
            name="email"
            placeholder={app.settings.translations['email']}
            value={formValues.email}
            type="email"
            onChange={(e) => {
              setFormValues({ ...formValues, email: e.target.value })
            }}
          />
        </div>
        <div className="form-input">
          <input
            required
            name="topic"
            placeholder={app.settings.translations['topic']}
            value={formValues.topic}
            type="subject"
            onChange={(e) => {
              setFormValues({ ...formValues, topic: e.target.value })
            }}
          />
        </div>
        <div className="form-input form-textarea">
          <textarea
            required
            placeholder={app.settings.translations['message']}
            name="message"
            id="message"
            value={formValues.message}
            onChange={(e) => {
              setFormValues({ ...formValues, message: e.target.value })
            }}
          />
        </div>
        <Button type="submit" className="send-button">
          {loadingState ? (
            <PulseLoader color="#75413d" />
          ) : (
            app.settings.translations['send']
          )}
        </Button>
      </form>
      <div
        className={functions.classnames(
          'last-message',
          formStatus !== 'default' && 'last-message--active',
          formStatus === 'success' && 'last-message--sent',
          formStatus === 'error' && 'last-message--error'
        )}
      >
        {formStatus === 'success'
          ? app.settings.translations['thanks']
          : formStatus === 'error'
          ? app.settings.translations['badResponse']
          : null}
      </div>
    </Column>
  )
}

export default StgForm
