import React from 'react'

import type { IFrameProps } from './types'

const IFrame: React.ForwardRefRenderFunction<HTMLIFrameElement, IFrameProps> = (
  props,
  ref
) => {
  return <iframe title="iframe" ref={ref} tabIndex={-1} {...props} />
}

export default React.forwardRef(IFrame)
