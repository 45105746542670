import React from 'react'
import { FooterProps } from './types'
import { useApp, useWidth } from '@wap-client/core'
import { Column, Container, Row } from '@/components/base/gridview'
import Anchor from '@/components/base/anchor'
import Image from '@/components/base/image'
import Icon from '@/components/base/icon'
import Iframe from '@/components/base/iframe'
import HorizontalDriver from '@/components/widgets/horizontal-driver'
import StgForm from './Form'

const Footer: React.FC<FooterProps> = ({
  adress,
  email,
  logo,
  phone,
  instagram,
  mapcode,
  link,
  adressCity,
}) => {
  const app = useApp()
  const width = useWidth()

  return (
    <footer id="footer">
      <Container size="extended">
        <HorizontalDriver />
        <Row
          className="footer-row"
          xs={{ direction: 'column-reverse' }}
          md={{ direction: 'row' }}
        >
          <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 6 }}>
            <div className="contacts">
              <figure className="contacts-logo">
                <Image
                  {...logo}
                  alt={app.settings.translations['brand-name'] + 'Logo'}
                />
              </figure>
              <div className="contacts-items">
                <div className="contacts-item brand-name">
                  <span className="text">
                    {app.settings.translations['brand-name']}
                  </span>
                </div>
                <div className="contacts-item adress">
                  <Icon size="large" name="location-pin" />
                  <div className="adress-text">
                    {adress && (
                      <span
                        className="text"
                        dangerouslySetInnerHTML={{ __html: adress }}
                      ></span>
                    )}
                    <span className="text">{adressCity}</span>
                  </div>
                </div>
                <div className="contacts-item phone">
                  <Icon size="large" name="phone" />
                  <a href={`tel:${phone}`} className="text">
                    {phone}
                  </a>
                </div>
                <div className="contacts-item mail">
                  <Icon size="large" name="mail-outline" />
                  <a href={`mailto:${email}`} className="text">
                    {email}
                  </a>
                </div>
                <div className="contacts-item instagram">
                  <Icon size="large" name="icon-instagram" />
                  <a
                    href={`https://www.instagram.com/${instagram}`}
                    target="_blank"
                    className="text"
                  >
                    {instagram}
                  </a>
                </div>
              </div>
            </div>

            <div className="contact-map">
              <Iframe src={mapcode} />
              <Anchor {...link}>
                <span className="text">
                  {app.settings.translations['view-larger-map']}
                </span>
              </Anchor>
            </div>
          </Column>

          <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 5 }}>
            <StgForm />
          </Column>
        </Row>
        <Row>
          <Column xs={{ size: 12 }}>
            <div className="copyright-layout">
              <span className="copyright-layout-text">
                {app.settings.translations['copyright']}
              </span>
            </div>
          </Column>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
